<template>
	<div class="gnrhsignin">
		<pre>传感器和发射器（佩戴部件）移除和更换<table class="tb_install" cellpadding="0" cellspacing="0">
			<tbody>
				<tr>
					<td style="width:50%;"><img src="../../../assets/h5Images/icon_remove2.png"/></td>
					<td style="width:50%;">1. 佩戴十四天后，需要拆卸发射器与传感器，将胶贴边缘向上轻轻的拉起，缓慢地将佩戴部件从佩戴部位的皮肤上撕下，即可完成佩戴部件的移除。
2. 用户需将使用完毕的传感器和发射器作为医疗垃圾处理。用户可联系客服，进一步了解有关如何处理佩戴部件废弃物的详细情况。
<b>注意：若用户通过持续葡萄监测系统所获得的血糖读数与当前的感受不相符，需检查传感器的佩戴情况，并进行指尖血糖测试以确认您的葡萄糖水平。如果问题仍然存在，移除当前传感器。
注意：产品的有效工作时长为14天。当产品已佩戴14天之后，将停止更新并停止上传数据，用户必须按照以上步骤尽快将产品进行移除。</b></td>
				</tr>
			</tbody>
		</table></pre>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				imgWidth: "width:100px;"
			}
		},
		created() {
			//获取屏幕宽度，计算一半的宽度。然后去设置
			var width = document.documentElement.clientWidth;
			console.log(width);
			this.imgWidth = "width:" + (width / 2 - 30) + "px";
		}
	}
</script>

<style scoped="scoped">
	.gnrhsignin {
		min-height: 100%;
		background-color: #fff;
		width: 100%;
		overflow-x: hidden;
	}

	.gnrhsignin h1 {
		text-align: center;
		font-size: 1.2rem;
	}

	.gnrhsignin pre {
		font-size: 1.2rem;
		line-height: 2rem;
		white-space: pre-wrap;
		word-wrap: break-word;
		line-break: anywhere;
		margin: 1.25rem;
		margin-top: 1rem;
	}

	.tb_install {
		font-size: 10pt;
		width: 100%;
		border-top: 1px solid #000;
		border-left: 1px solid #000;
	}

	.tb_install th {
		background-color: rgb(216, 216, 216);
		border-bottom: 1pt solid windowtext;
		border-right: 1pt solid windowtext;
	}

	.tb_install td {
		padding-left: 0.25rem;
		border-bottom: 1pt solid windowtext;
		border-right: 1pt solid windowtext;
		line-height: 1.5rem;
		white-space: pre-wrap;
		word-wrap: break-word;
		line-break: anywhere;
	}

	.tb_install td img {
		width: 100%;
	}

	.tb_install tr td:first-child {
		text-align: center;
	}
</style>
<style>
	#app {
		min-width: 100% !important;
	}
</style>
